import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  html {
    font-size: 15px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, Ubuntu, Helvetica Neue, PingFang SC, Microsoft YaHei, Source Han Sans SC, Noto Sans CJK SC, WenQuanYi Micro Hei, sans-serif;
    -webkit-font-smoothing: antialiased;  
    max-width: 1200px;
    margin: 0 auto;
  }

  a {
    color: #fff;
    text-decoration: none;

  }

  ul, li, figure {
    margin: 0;
    padding: 0;
  }
`

export default GlobalStyle
